import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { ImageDataLike, getImage } from 'gatsby-plugin-image'
import LocaleContext from '../../context/LocaleContext'
import PageContext from '../../context/PageContext'
import { Locale, localisePath } from '../../domain/locale'
import { PRODUCTS_PATH } from '../../domain/path'
import { ProductCategory, createProductUrl } from '../../domain/product'
import Layout from '../../components/layout/Layout'
import Seo from '../../components/layout/Seo'
import CategoryCard from '../../components/products/CategoryCard'

// Define build-time GraphQL query
export const pageQuery = graphql`
    query AUProductsQuery {
        products: allShopifyProduct(
            sort: {
                fields: variants___product___variants___createdAt
                order: ASC
            }
        ) {
            edges {
                node {
                    id
                    title
                    featuredImage {
                        id
                        localFile {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED)
                            }
                        }
                        altText
                    }
                    productType
                }
            }
        }
    }
`

// Type definitions for GraphQL query
type Edge = {
    node: {
        id: string
        title: string
        featuredImage: {
            id: string
            localFile: ImageDataLike
            altText: string
        }
        productType: string
    }
}

type Props = {
    data: {
        products: {
            edges: Edge[]
        }
    }
}

/** Collects similar product types (called categories) */
function getProductCategories(edges: Edge[]): ProductCategory[] {
    let categories: ProductCategory[] = []
    let categoriesKeys: string[] = []

    for (let edge of edges) {
        const { productType, featuredImage, title } = edge.node

        // If the category has not been added, add it
        if (!categoriesKeys.includes(productType)) {
            categories.push({
                id: productType,
                name: productType,
                image: {
                    id: featuredImage.id,
                    data: getImage(featuredImage.localFile),
                    altText: featuredImage.altText,
                },
                featuredProductUrl: localisePath(
                    createProductUrl(title),
                    Locale.AU
                ),
            })
            categoriesKeys.push(productType)
        }
    }
    return categories
}

const ProductsPage = ({ data: { products } }: Props) => {
    const { setLocale } = useContext(LocaleContext)

    useEffect(() => {
        setLocale(Locale.AU)
    }, [])

    const categories = getProductCategories(products.edges)

    return (
        <PageContext.Provider
            value={{
                basepath: PRODUCTS_PATH,
                locale: Locale.AU,
                localised: true,
            }}
        >
            <Layout>
                <Seo
                    title="Products"
                    titleCompany="Telobag AU"
                    description="Telobag makes cassava-starch bags that are organic and thus 100% biodegradable and compostable"
                    relativeUrl={localisePath(PRODUCTS_PATH, Locale.AU)}
                />

                {/* Title and short horizontal rule underneath */}
                <h1 className="font-bold text-3xl md:text-5xl mb-3 text-primary">
                    Products
                </h1>
                <hr className="border-b border-gainsboro w-10" />

                {/* Product categories displayed as cards */}
                <div className="flex flex-wrap -mx-6">
                    {categories.map(props => (
                        <CategoryCard key={props.name} {...props} />
                    ))}
                </div>
            </Layout>
        </PageContext.Provider>
    )
}

export default ProductsPage
