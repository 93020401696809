import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ProductCategory } from '../../domain/product'

function CategoryCard({ name, image, featuredProductUrl }: ProductCategory) {
    return (
        <article className="p-6 w-full md:w-1/2 lg:w-1/3">
            <Link
                to={featuredProductUrl}
                className="group no-underline w-full h-full flex"
            >
                <div className="bg-gainsboro rounded-lg cursor-pointer w-full overflow-hidden relative px-3 py-6 md:px-6">
                    <GatsbyImage
                        image={image.data}
                        alt={!!image.altText ? image.altText : image.id}
                    />
                    <div className="pt-3 md:pt-6 text-center">
                        <p className="text-slategray font-bold text-lg group-hover:text-primary mb-1">
                            {name}
                        </p>
                    </div>
                </div>
            </Link>
        </article>
    )
}

export default CategoryCard
